
import { useEffect } from 'react'
import Select from 'react-select'

import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../Helper/Context/context'
import { FinantialYearDDLAPI } from '../../Helper/Redux/DDLSlice/DDLSlice'


export const FinancialYearDDL = (props) => {
    const { DDLFinantialYearID, setDDLFinantialYearID, Flag, apiFlag, data } = props

    const dispatch = useDispatch()
    const userDetails = useAuthState();
    const { UserID, token } = userDetails


    useEffect(() => {
        const data = {
            UserID: UserID,
            token: token
        }
        dispatch(FinantialYearDDLAPI({ data }))
    }, [])

    const { DDLData } = useSelector(state => state.FinantialYearDDLData)

    useEffect(() => {
        handleDDL()
    }, [DDLData])

    const handleDDL = () => {
        if (DDLData && DDLData.length > 0) {
            let list = DDLData.map((item, index) => ({
                value: item.valueID,
                label: item.valueParam,
            })).reverse();
            setDDLFinantialYearID({
                DDL: list,
                ID: Flag == 'Edit' ? data.m_FinancialYearID : list[0].value,
                Label: Flag == 'Edit' ? data.financialYear : list[0].label,
            })
        }
        else {
            setDDLFinantialYearID({
                DDL: [],
                ID: 0,
                Label: "Year",
            })
        }
    }

    return (
        <div className="" style={{ width: '100px' }}>
            {/* <label className="d-block" htmlFor="NameofDepartment">Area</label>  */}
            <Select
                isClearable={false}
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: state.isFocused ? 'transparent' : 'transparent',
                        borderBottomColor: 'black',
                        boxShadow: 'none'
                    }),
                    option: (provided, state) => ({
                        ...provided,
                        color: 'black'
                    })
                }}
                components={{
                    IndicatorSeparator: () => null
                }}
                isSearchable
                maxMenuHeight={150}
                value={{ value: DDLFinantialYearID.ID, label: DDLFinantialYearID.Label }}
                onChange={(e) => {
                    e ?
                        setDDLFinantialYearID({ ...DDLFinantialYearID, ID: e.value, Label: e.label })
                        :
                        setDDLFinantialYearID({ ...DDLFinantialYearID, ID: 0, Label: "Year" })

                }}
                options={DDLFinantialYearID.DDL}
            />
        </div>
    )
}